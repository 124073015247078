import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { TextInput } from './TextInput';
import { Button } from './Button';
import { Spacing } from '../styles';

class FillInTheBlankAnswerCreator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      answers:
        (props.initialAnswerContainer.answers &&
          props.initialAnswerContainer.answers.length) > 0
          ? props.initialAnswerContainer.answers
          : [''],
    };
    props.onChange(this.state);

    this.handleAddAnswer = this.handleAddAnswer.bind(this);
  }

  handleAddAnswer() {
    let newAnswers = this.state.answers;
    newAnswers.push('');
    this.setState({ answers: newAnswers }, () => {
      this.props.onChange(this.state);
    });
  }

  handleTextChange(i, text) {
    let newAnswers = this.state.answers;
    newAnswers[i] = text;
    this.setState({ answers: newAnswers }, () => {
      this.props.onChange(this.state);
    });
  }

  handleDeleteAnswer(i) {
    let newAnswers = this.state.answers;
    if (newAnswers.length > 1) {
      newAnswers.splice(i, 1);
    }
    this.setState({ answers: newAnswers }, () =>
      this.props.onChange(this.state)
    );
  }

  render() {
    let answerInputs = [];
    for (let i = 0; i < this.state.answers.length; i++) {
      answerInputs.push(
        <View key={i} style={styles.answerInput}>
          <TextInput
            placeholder="Answer"
            accessibilityLabel="Enter answer"
            value={this.state.answers[i]}
            onChangeText={((i) => {
              return (text) => this.handleTextChange(i, text);
            })(i)}
            style={styles.answerTextField}
          />
          <Button
            accessibilityLabel="Delete answer"
            onPress={((i) => {
              return () => this.handleDeleteAnswer(i);
            })(i)}
            style={{ marginLeft: Spacing.base }}
            mode="contained"
          >
            X
          </Button>
        </View>
      );
    }

    return (
      <View>
        {answerInputs}
        <Button onPress={this.handleAddAnswer} mode="contained">
          Add another answer
        </Button>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  answerInput: {
    flexDirection: 'row',
    marginBottom: Spacing.base,
    alignItems: 'center',
  },
  answerTextField: {
    flex: 5,
  },
});

FillInTheBlankAnswerCreator.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialAnswerContainer: PropTypes.object.isRequired,
};

export default FillInTheBlankAnswerCreator;
