import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-native-paper';
import MultipleChoiceAnswerView from './MultipleChoiceAnswerView';
import TrueFalseAnswerView from './TrueFalseAnswerView';
import MatchingAnswerView from './MatchingAnswerView';
import FillInTheBlankAnswerView from './FillInTheBlankAnswerView';
import { questionTypes } from '../modules/QuestionTypes';

class AnswerView extends React.Component {
  render() {
    switch (this.props.type) {
      case questionTypes.multipleChoice:
        return (
          <MultipleChoiceAnswerView
            answerContainer={this.props.answerContainer}
            onChange={this.props.onChange}
            currentAnswer={this.props.currentAnswer}
          />
        );
      case questionTypes.fillInTheBlank:
        return (
          <FillInTheBlankAnswerView
            onChange={this.props.onChange}
            currentAnswer={this.props.currentAnswer}
          />
        );
      case questionTypes.trueFalse:
        return (
          <TrueFalseAnswerView
            onChange={this.props.onChange}
            currentAnswer={this.props.currentAnswer}
          />
        );
      case questionTypes.matching:
        return (
          <MatchingAnswerView
            answerContainer={this.props.answerContainer}
            onChange={this.props.onChange}
            currentAnswer={this.props.currentAnswer}
          />
        );
      case questionTypes.essay:
        return (
          <TextInput
            onChangeText={this.props.onChange}
            value={this.props.currentAnswer}
          />
        );
      default:
        return null;
    }
  }
}

AnswerView.propTypes = {
  type: PropTypes.oneOf(Object.values(questionTypes)).isRequired,
  answerContainer: PropTypes.object.isRequired,
  currentAnswer: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default AnswerView;
