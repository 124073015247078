import 'react-native-gesture-handler';
import React from 'react';
import TestCreator from './src/components/TestCreator';
import HomeScreen from './src/components/HomeScreen';
import TestSelectionScreen from './src/components/TestSelectionScreen';
import ImportExportScreen from './src/components/ImportExportScreen';
import TestTakingScreen from './src/components/TestTakingScreen';
import GradeScreen from './src/components/GradeScreen';
import EditTestScreen from './src/components/EditTestScreen';
import ContactUsScreen from './src/components/ContactUsScreen';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Colors, Buttons } from './src/styles';
import { Image } from 'react-native';
import {
  configureFonts,
  DefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import Footer from './src/components/Footer';
import CookieConsentMessage from './src/components/CookieConsentMessage';
import { Button } from './src/components/Button';

const Stack = createStackNavigator();

const navigationTheme = {
  dark: false,
  colors: {
    primary: Colors.red,
    background: Colors.foreground,
    card: Colors.background,
    text: Colors.foreground,
  },
};

const paperFontConfig = {
  default: {
    regular: {
      fontFamily: 'sans-serif',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'sans-serif-medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'sans-serif-light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'sans-serif-thin',
      fontWeight: 'normal',
    },
  },
};

const paperTheme = {
  ...DefaultTheme,
  roundness: 1,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.red,
    accent: Colors.gold,
  },
  fonts: configureFonts(paperFontConfig),
};

const mainMenuButton = {
    backgroundColor: Colors.blue,
    borderRadius: 5,
    padding: 2,
    //width:120,
    //marginBottom: 10,
    marginRight: 10,
    
};

function App() {
  return (
    <PaperProvider theme={paperTheme}>
      <NavigationContainer theme={navigationTheme}>
        <Stack.Navigator initialRouteName="Home" tabIndex="1" screenOptions={({ navigation }) => ({
              headerRight   : () => (
                <Button tabIndex="1" style={[Buttons.base,mainMenuButton]} 
                  onPress={() => navigation.navigate('Home')}
                ><span style={{ color: '#FFFFFF' }}>Main Menu</span></Button>
              ),
              headerMode: 'none',
              headerStyle: {
                color:'white',
                tabIndex:'1'
              },
            })}>
          <Stack.Screen
          
            name="Home"
            component={HomeScreen}
            options={{ 
              headerLeft   : () => (
                <Image tabIndex="1"
                style={{width: 40, height: 40, margin: 20}}
                source={require('./src/images/tp-logo-transp.png')}
                />
              ),
              headerRight   : () => (""),
              tabIndex:"0",
              headerMode: 'none',
              title: "Teacher's Pet",
            }}
          />
          <Stack.Screen
            name="CreateTest"
            component={TestCreator}
            options={{ title: 'Create test' }}
          />
          <Stack.Screen
            name="SelectTest"
            component={TestSelectionScreen}
            options={{ title: 'Take a test' }}
          />
          <Stack.Screen
            name="ImportExport"
            component={ImportExportScreen}
            options={{ title: 'Import and export tests' }}
          />
          <Stack.Screen 
            name="TakeTest" 
            component={TestTakingScreen} />
          <Stack.Screen
            name="Grade"
            component={GradeScreen}
            options={{ title: 'Grade test', headerLeft: null }}
          />
          <Stack.Screen
            name="EditTest"
            component={EditTestScreen}
            options={{ title: 'Edit a test' }}
          />
          <Stack.Screen
          
            name="ContactUs"
            component={ContactUsScreen}
            options={{ title: 'Contact Us' }}
          />
        </Stack.Navigator>
      </NavigationContainer>
      <CookieConsentMessage/>
      <Footer/> 
    </PaperProvider>
  );
}

export default App;
