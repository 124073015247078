import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Switch } from 'react-native-paper';
import { RadioButton } from './RadioButton';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { Containers, Buttons, Spacing } from '../styles';

class MultipleChoiceAnswerCreator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      choices:
        (props.initialAnswerContainer.choices &&
          props.initialAnswerContainer.choices.length) > 0
          ? props.initialAnswerContainer.choices
          : [''],
      answer: props.initialAnswerContainer.answer || 0,
    };
    props.onChange(this.state);

    this.handleCorrectAnswerSwitch = this.handleCorrectAnswerSwitch.bind(this);
    this.handleNewChoice = this.handleNewChoice.bind(this);
    this.handleDeleteChoice = this.handleDeleteChoice.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handleCorrectAnswerSwitch(i) {
    let newChoices = this.state.choices;
    if (this.state.choices[i] === undefined) newChoices[i] = '';
    this.setState({ choices: newChoices, answer: i }, () =>
      this.props.onChange(this.state)
    );
  }

  handleNewChoice(i) {
    let newChoices = this.state.choices;
    newChoices.splice(i + 1, 0, '');
    this.setState({ choices: newChoices }, () =>
      this.props.onChange(this.state)
    );
  }

  handleDeleteChoice(i) {
    let newChoices = this.state.choices;
    let newIndex = this.state.answer;
    if (newChoices.length > 1) {
      newChoices.splice(i, 1);
    }
    if (newIndex > newChoices.length - 1) {
      newIndex = newChoices.length - 1;
    }
    this.setState({ answer: newIndex, choices: newChoices }, () =>
      this.props.onChange(this.state)
    );
  }

  handleTextChange(i, text) {
    let newChoices = this.state.choices;
    newChoices[i] = text;
    this.setState({ choices: newChoices }, () =>
      this.props.onChange(this.state)
    );
  }

  render() {
    let answerInputs = [];

    for (let i = 0; i < this.state.choices.length; i++) {
      answerInputs.push(
        <View key={i} style={styles.answerInput}>
          <View style={styles.correctAnswerSwitch}>
            <RadioButton
              value={i}
              accessibilityLabel={`Mark answer as correct`}
            />
          </View>
          <TextInput
            placeholder="Enter answer choice here..."
            value={this.state.choices[i] || ''}
            onChangeText={((i) => {
              return (text) => {
                this.handleTextChange(i, text);
              };
            })(i)}
            style={styles.answerTextField}
          />
          <Button
            style={[Buttons.base, Spacing.baseStyle]}
            accessibilityLabel="Add new answer choice"
            onPress={((i) => {
              return () => {
                this.handleNewChoice(i);
              };
            })(i)}
            mode="contained"
          >
            +
          </Button>
          <Button
            style={[Buttons.base, Spacing.baseStyle]}
            accessibilityLabel="Delete answer choice"
            onPress={((i) => {
              return () => {
                this.handleDeleteChoice(i);
              };
            })(i)}
            mode="contained"
          >
            X
          </Button>
        </View>
      );
    }
    return (
      <View style={Containers.base}>
        <RadioButton.Group
          onValueChange={this.handleCorrectAnswerSwitch}
          value={this.state.answer}
        >
          {answerInputs}
        </RadioButton.Group>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#222',
    padding: 5,
    marginBottom: 10,
  },
  answerInput: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5,
  },
  answerTextField: {
    flex: 5,
  },
  correctAnswerSwitch: {
    marginRight: 10,
    justifyContent: 'center',
  },
  actionButton: {
    flex: 1,
    marginHorizontal: 5,
  },
  separator: {
    flex: 1,
  },
});

MultipleChoiceAnswerCreator.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialAnswerContainer: PropTypes.object.isRequired,
};

export default MultipleChoiceAnswerCreator;
