import { registerRootComponent } from 'expo';
import App from './App';

//Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-200260902-1'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
