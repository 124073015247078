import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View } from 'react-native';
import TestStorage from '../modules/TestStorage';
import Alert from '../modules/Alert';
import { Borders } from '../styles';

class AsyncTestPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.handleSelectTest = this.handleSelectTest.bind(this);
  }

  async componentDidMount() {
    try {
      const userTestList = await TestStorage.getUserTests();
      this.userTestLength = userTestList.length;
      const testList = this.props.showDefaults
        ? userTestList.concat(await TestStorage.getDefaultTests())
        : userTestList;
      this.props.onTestListChange(testList);
    } catch (e) {
      console.error('There was an error picking the test: '+e);
      Alert.alert('There was an error: ' + e);
    } finally {
      this.setState({ isLoading: false });
      this.props.onSelectionChange(0, 0 >= this.userTestLength);
    }
  }

  handleSelectTest(event) {
    this.props.onSelectionChange(parseInt(event.target.value), event.target.value >= this.userTestLength);
  }

  render() {
    return (
      <View>
        {this.state.isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <select
              style={{height: 30 + 'px',backgroundColor: '#ffffff', ...Borders.thinRound}} 
              accessibilitylabel="Pick test"
              value={this.props.selectedTest}
              onChange={this.handleSelectTest}
            >
              {this.props.testList.map((value, index) => (
                <option value={index} key={value} >{value}</option> 
              ))}
            </select>
          </>
        )}
      </View>
    );
  }
}

AsyncTestPicker.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  selectedTest: PropTypes.number.isRequired,
  testList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTestListChange: PropTypes.func.isRequired,
  showDefaults: PropTypes.bool,
};

export default AsyncTestPicker;
