import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, findNodeHandle, Text } from 'react-native';
import { Button } from './Button';
import AsyncTestPicker from './AsyncTestPicker';
import TestCreator from './TestCreator';
import TestStorage from '../modules/TestStorage';
import validateTestPassWord from '../modules/testPassword';
import SendPageView from '../modules/GoogleAnalitycs';

class EditTestScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testList: [],
      selectedTest: 0,
      selection: true,
      testData: {},
    };

    this.handleSelectTest = this.handleSelectTest.bind(this);
    this.editTest = this.editTest.bind(this);
  }

  componentDidMount(){
    setTimeout(function(){SendPageView();}, 500);
    findNodeHandle(this.nameText).focus();
  }

  handleSelectTest(value) {
    this.setState({ selectedTest: parseInt(value) });
  }

  async editTest() {
      try {
      const testData = await TestStorage.exportTest(
        this.state.testList[this.state.selectedTest]
      );
      if(validateTestPassWord(testData)){
        this.setState({ selection: false, testData });
      }
      }catch (e) {
          console.error('There was an error updating the current test info: '+e);
      }
  }

  render() {
    return (
      <View style={styles.container}>
        <Text  
          ref={(input) => {
            this.nameText = input;
          }} 
          accessible>Choose a test to edit:</Text>
        {this.state.selection ? (
          <>
            <AsyncTestPicker
              onSelectionChange={(selectedTest) =>
                this.setState({ selectedTest })
              }
              onTestListChange={(testList) => this.setState({ testList })}
              selectedTest={this.state.selectedTest}
              testList={this.state.testList}
            />
            <View style={styles.container}>
              <Button mode="contained" onPress={this.editTest}>
                Edit test
              </Button>
            </View>
          </>
        ) : (
          <TestCreator initialTest={this.state.testData} navigation= {this.props.navigation} />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

EditTestScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default EditTestScreen;
