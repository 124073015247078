import React from 'react';
import { Spacing, Containers, Borders, Typography, Buttons } from '../styles';
import { Checkbox } from 'react-native-paper';
import { Button } from './Button';
import { TextInput } from './TextInput';
import { Text, ScrollView, StyleSheet, View, findNodeHandle } from 'react-native';
import PropTypes from 'prop-types';
import QuestionCreator from './QuestionCreator';
import { v4 as uuidv4 } from 'uuid';
import TestStorage from '../modules/TestStorage';
import Alert from '../modules/Alert';
import validator from 'email-validator';
import { Colors } from '../styles';
import SendPageView from '../modules/GoogleAnalitycs';

class TestCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: (props.initialTest && props.initialTest.questions) || [],
      title: (props.initialTest && props.initialTest.title) || '',
      description: (props.initialTest && props.initialTest.description) || '',
      password: (props.initialTest && props.initialTest.password) || '',
      time: (props.initialTest && props.initialTest.time) || 0,
      isTimed: (props.initialTest && props.initialTest.isTimed) || false,
      randomizeTest:
        (props.initialTest && props.initialTest.randomizeTest) || false,
      points: (props.initialTest && props.initialTest.points) || 100,
      multipleChoice:
        (props.initialTest && props.initialTest.multipleChoice) || 0,
      trueFalse: (props.initialTest && props.initialTest.trueFalse) || 0,
      matching: (props.initialTest && props.initialTest.matching) || 0,
      fillInTheBlank:
        (props.initialTest && props.initialTest.fillInTheBlank) || 0,
      essay: (props.initialTest && props.initialTest.essay) || 0,
      email: (props.initialTest && props.initialTest.email) || '',
    };

    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleSaveTest = this.handleSaveTest.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleDeleteTest = this.handleDeleteTest.bind(this);
   }
   
  componentDidMount(){
    setTimeout(function(){SendPageView();}, 500);
    findNodeHandle(this.testTitleFocus).focus();
  }

  handleAddQuestion() {
    let newQuestions = this.state.questions;
    //this.flatlist.scrollToEnd();
    newQuestions.push({ id: uuidv4() });
    this.setState({ questions: newQuestions });
  }

  handleDeleteQuestion(i) {
    let newQuestions = this.state.questions;
    newQuestions.splice(i, 1);
    this.setState({ questions: newQuestions });
  }

  handleChangeQuestion(i, question) {
    let newQuestions = this.state.questions;
    newQuestions[i] = question;
    this.setState({ questions: newQuestions });
  }

  async handleSaveTest() {
    if (this.state.title === '') {
      Alert.alert('Please give the test a title');
      return;
    }
    if(this.state.password != ''){
      if(!validator.validate(this.state.email)){
        Alert.alert('Please enter a valid email');
      return;
      }
    }
    if (this.state.email && !validator.validate(this.state.email)) {
      Alert.alert('Please input a valid email or leave blank');
      return;
    }
    try {
      await TestStorage.importTest(this.state, false);
      Alert.alert('Test saved successfully');
    } catch (e) {
      const choice = Alert.confirm(
        'A test already exists with this name. Overwrite it?'
      );
      if (choice) {
        try {
          await TestStorage.importTest(this.state, true);
          Alert.alert('Test saved successfully');
        } catch (e) {
          Alert.alert(
            'There was an error saving your test and it was not saved. Try exporting some tests and clearing your local storage.'
          );
        }
      }
    }
  }

  async handleDeleteTest() {
    const choice = Alert.confirm(
      'Are you sure you want to delete the test: '+this.state.title+'?'
    );
    if (choice) {
      try {
        await TestStorage.deleteTest(this.state);
        Alert.alert('Test deleted successfully');
        this.props.navigation.navigate('Home');
      } catch (err) {
        console.error(err);
        Alert.alert(
          'There was an error deleting your test and it was not deleted.'
        );
      }
    }

  }

  handleChangeTime(time) {
    if (time === ''|| time <= 0) {
      time = 0;
    }
    this.setState({ time: parseInt(time) });
    this.setState({ isTimed: !!time && parseInt(time) > 0 });
  }

  render() {
    let questionElements = [];
    for (let i = 0; i < this.state.questions.length; i++) {
      questionElements.push(
        <QuestionCreator
        placeholder="Total number of desired points"
          last={i >= this.state.questions.length - 1}
          initialQuestion={this.state.questions[i] || {}}
          onChange={((i) => {
            return (question) => {
              this.handleChangeQuestion(i, question);
            };
          })(i)}
          questionNumber={i + 1}
          onDelete={((i) => {
            return () => {
              this.handleDeleteQuestion(i);
            };
          })(i)}
        />
      );
    }

    let extractKey = (item) => item.props.initialQuestion.id;

    return (
      <View style={Containers.base}>
        <View style={Containers.textFieldContainer}>
          <Text ref={(input) => {
              this.testTitleFocus = input;
            }}  
            accessible style={[Containers.label, Typography.medium]}>Test Title:</Text>
          <TextInput
            placeholder="Test Title"
            style={Containers.textInput}
            onChangeText={(title) => this.setState({ title })}
            value={this.state.title}
            accessible
          />
        </View>
        <View  style={Containers.textFieldContainer}>
          <Text accessible style={[Containers.label, Typography.medium]}>
            Test Description:
          </Text>
          <TextInput
            placeholder="Test Description"
            style={Containers.textInput}
            onChangeText={(description) => this.setState({ description })}
            value={this.state.description}
            accessible
          />
        </View>
        <View style={Containers.textFieldContainer}>
          <Text style={[Containers.label, Typography.medium]}>
            Password:
          </Text>
          <TextInput
            placeholder="Password"
            //secureTextEntry={true}
            style={Containers.textInput}
            onChangeText={(password) => this.setState({ password })}
            value={this.state.password}
          />
        </View>
        <View style={Containers.textFieldContainer}>
          <Text style={[Containers.label, Typography.medium]}>
            Email test results to (Mandatory if test is password protected):
          </Text>
          <TextInput
            placeholder="Email address"
            style={Containers.textInput}
            onChangeText={(email) => this.setState({ email })}
            value={this.state.email}
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Test Time in minutes:
          </Text>
          <TextInput
            placeholder="Test Time in minutes"
            style={Containers.textInput}
            keyboardType="numeric"
            onChangeText={this.handleChangeTime}
            accessibilityLabel="Minutes"
            value={
              this.state.time.toString() === '0'
                ? ''
                : this.state.time.toString()
            }
          />
        </View>
        <View  style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Randomized test:
          </Text>
          <Checkbox
            onPress={() =>
              this.setState({ randomizeTest: !this.state.randomizeTest })
            }
            status={this.state.randomizeTest ? 'checked' : 'unchecked'}
            accessibilityLabel="Randomize test questions"
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Number of multiple choice:
          </Text>
          <TextInput
            disabled={!this.state.randomizeTest}
            placeholder="Number of multiple choice questions to use from pool"
            style={Containers.textField}
            keyboardType="numeric"
            onChangeText={(number) =>
              this.setState({ multipleChoice: parseInt(number) || 0 })
            }
            value={
              this.state.multipleChoice.toString() === '0'
                ? ''
                : this.state.multipleChoice.toString()
            }
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Number of fill in the blank:
          </Text>
          <TextInput
            disabled={!this.state.randomizeTest}
            placeholder="Number of fill in the blank questions to use from pool"
            style={Containers.textInput}
            keyboardType="numeric"
            onChangeText={(number) =>
              this.setState({ fillInTheBlank: parseInt(number) || 0 })
            }
            value={
              this.state.fillInTheBlank.toString() === '0'
                ? ''
                : this.state.fillInTheBlank.toString()
            }
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Number of true or false:
          </Text>
          <TextInput
            disabled={!this.state.randomizeTest}
            placeholder="Number of true or false questions to use from pool"
            style={Containers.textInput}
            keyboardType="numeric"
            onChangeText={(number) =>
              this.setState({ trueFalse: parseInt(number) || 0 })
            }
            value={
              this.state.trueFalse.toString() === '0'
                ? ''
                : this.state.trueFalse.toString()
            }
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Number of matching:
          </Text>
          <TextInput
            disabled={!this.state.randomizeTest}
            placeholder="Number of matching questions to use from pool"
            style={Containers.textInput}
            keyboardType="numeric"
            onChangeText={(number) =>
              this.setState({ matching: parseInt(number) || 0 })
            }
            value={
              this.state.matching.toString() === '0'
                ? ''
                : this.state.matching.toString()
            }
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Number of essays:
          </Text>
          <TextInput
            disabled={!this.state.randomizeTest}
            placeholder="Number of essay questions to use from pool"
            style={Containers.textInput}
            keyboardType="numeric"
            onChangeText={(number) =>
              this.setState({ essay: parseInt(number) || 0 })
            }
            value={
              this.state.essay.toString() === '0'
                ? ''
                : this.state.essay.toString()
            }
          />
        </View>
        <View style={Containers.textFieldContainer, styles.hide}>
          <Text style={[Containers.label, Typography.medium]}>
            Total number of desired points:
          </Text>
          <TextInput
            disabled={!this.state.randomizeTest}
            placeholder="Total number of desired points"
            style={Containers.textInput}
            keyboardType="numeric"
            onChangeText={(number) =>
              this.setState({ points: parseInt(number) || 0 })
            }
            value={
              this.state.points.toString() === '0'
                ? ''
                : this.state.points.toString()
            }
          />
        </View>
        <ScrollView style={styles.questionList}>
        {questionElements}
        {this.state.questions.length == '0' ? (
          <Text style={[Containers.label, Typography.medium]}>
            No questions added.
          </Text>
        ) : (
          <Text></Text>
        )}
        </ScrollView>
        <Button
          mode="contained"
          onPress={this.handleAddQuestion}
          style={[Buttons.base,{ backgroundColor: Colors.blue } ]}
        >
          Add new question
        </Button>
        <Button 
          mode="contained" 
          onPress={this.handleSaveTest}
          style={[Buttons.base,{ backgroundColor: Colors.blue } ]}>
          Save Test
        </Button>
        {this.props.initialTest != null ? (
        <Button 
          mode="contained" 
          onPress={this.handleDeleteTest}  
          style={[Buttons.base,{ backgroundColor: Colors.red } ]} >
          Delete Test
        </Button>
        ) : (<Text></Text>)}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  questionList: {
    height: 400,
    padding: Spacing.base,
    marginVertical: Spacing.base,
    ...Borders.thinRound,
    ...Borders.shadow,
  },
  questionListItem: {
    marginBottom: Spacing.base,
  },
  questionNumber: {
    flex: 1,
    marginRight: Spacing.base,
  },
  hide:{
    display: 'none'
  }
});

TestCreator.propTypes = {
  initialTest: PropTypes.object,
  navigation: PropTypes.object.isRequired,
};

export default TestCreator;
