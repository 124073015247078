let large = {
  fontSize: 23,
};

let medium = {
  fontSize: 18,
};

let small = {
  fontSize: 14,
};

export { large, medium, small };
