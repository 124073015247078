import * as Colors from './Colors';
import * as Borders from './Borders';
import * as Spacing from './Spacing';
import * as Typography from './Typography';

let link = {
  backgroundColor: Colors.red,
  ...Typography.large,
  margin: Spacing.base,
  padding: Spacing.base,
  ...Borders.rounded,
  ...Borders.shadow,
};

export { link };
