import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Alert, findNodeHandle } from 'react-native';
import { Button } from './Button';
import { Colors } from '../styles';
import AsyncTestPicker from './AsyncTestPicker';
import TestStorage from '../modules/TestStorage';

import { Typography } from '../styles';
import { Spacing } from '../styles';
import { Text } from 'react-native';
import SendPageView from '../modules/GoogleAnalitycs';



class TestSelectionScreen extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      testList: [],
      currentTest:[],
      selectedTest: 0,
      testIsSelected: false,
    };

    this.handleSelectTest = this.handleSelectTest.bind(this);
    this.startTest = this.startTest.bind(this);
  }

  componentDidMount(){
    setTimeout(function(){SendPageView();}, 500);
    findNodeHandle(this.chooseText).focus();
  }

  handleSelectTest(value, defaultTest) {
      this.setState({ selectedTest: parseInt(value), defaultTest });
      this.setState({ testIsSelected: true });
      this.updateCurrentTest(value, defaultTest);
  }

  async updateCurrentTest(value, defaultTest) {
    try {
      let test = await TestStorage.exportTest(
       this.state.testList[parseInt(value)],
       defaultTest);
       this.setState({currentTest:test})
      }catch (e) {
        console.error('There was an error updating the current test info: '+e);
        Alert.alert('There was an error: ' + e);
      }
  }

  async startTest() {
    const testData = await TestStorage.exportTest(
      this.state.testList[this.state.selectedTest],
      this.state.defaultTest
    );
    this.props.navigation.navigate('TakeTest', { test: testData });
  }

  render() {
    return (
      <View style={styles.container}>
        <Text  
          ref={(input) => {
            this.chooseText = input;
          }} 
          accessible>Choose a test:</Text>
        <AsyncTestPicker
          onTestListChange={(testList) => this.setState({ testList })}
          onSelectionChange={this.handleSelectTest}
          
          selectedTest={this.state.selectedTest}
          testList={this.state.testList}
          currentTest={this.state.currentTest}
          showDefaults
        />
        <View style={styles.container}>
        
          {this.state.testIsSelected &&
            <View style={styles.infoBox}>
              <Text
                accessible
                style={[Typography.large, { marginBottom: Spacing.base }]}
              >
                Test title: {this.state.currentTest.title}
              </Text>
              <Text accessible 
                style={[Typography.large, { marginBottom: Spacing.base }]}
              >
                Test description: {this.state.currentTest.description}
              </Text>
              {this.state.currentTest.isTimed ? (
                <Text accessible 
                style={[Typography.large, { marginBottom: Spacing.base }]}
                >
                  Test time: {this.state.currentTest.time} minutes
                </Text>
              ) : null}
            </View>
          }
          <Button
            onPress={this.startTest}
            mode="contained"
            style={{ backgroundColor: Colors.gold }}
          >
            Start test
          </Button>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

TestSelectionScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  //currentTest: PropTypes.object.isRequired,
};

export default TestSelectionScreen;
