import GA4React from "ga-4-react";
function SendPageView(customPath="") {
const path = customPath || document.title;
    const ga4react = new GA4React('G-LF6NW9PM0H');
    if(!GA4React.isInitialized()){
        ga4react.initialize().then((ga4) => {
            ga4.pageview(path)
          },(err) => {
            console.error(err)
          })
    }else{
        ga4react.pageview(path);
    }
    //console.log(path);
}

export default SendPageView;