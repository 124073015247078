import React from "react";
import CookieConsent from "react-cookie-consent";
import { Text} from "react-native"
import {Linking} from "expo"
import { Colors } from '../styles';

const CookieConsentMessage = () => (
  <CookieConsent
      location="none"
      buttonText="Got it!"
      cookieName="TeachersPetCookieConsent"        
      style={{ backgroundColor:Colors.white,  marginTop:150, textAlign: 'center', flex:0, display: "block"}}
      buttonStyle={{ backgroundColor:Colors.blue,color: Colors.white, fontSize: "13px", fontWeight: 'bold', marginRigth:'100px', textAlign: 'left', with:100}}
      contentStyle={{}}
      expires={365}
      onAccept={() => {window.location.reload()}
    }
    >
      <Text tabIndex="0" style={styles.textStyle}>This website uses cookies to ensure you get the best experience using Teacher's Pet.</Text>
      
      <Text style={{ fontSize: "10px" }}
            style={styles.hyperlinkStyle}
            onPress={() => {
              if (window) {
                return window.open('https://www.aph.org/privacy-policy/', '_blank');
              } else {
                return  Linking.openURL('https://www.aph.org/privacy-policy/', '_blank');
              } 
            }}>
            Learn more about our cookie policy.
      </Text>
      <Text tabIndex="0" style={styles.textStyle}>Please accept cookies to continue... {" "}</Text>
      
  </CookieConsent>
);


export default CookieConsentMessage;

const styles = {
  textStyle: {
    margin: 10,
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center'
  },
  hyperlinkStyle: {
    color: Colors.gold,
    fontStyle: 'italic',
    textDecorationLine: 'underline',
    
  },

};
