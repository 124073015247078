import axios from 'axios';
import Alert from '../modules/Alert';

function validateTestPassWord(test) {
  if(test.password == '' ||  typeof(test.password) === 'undefined'){
    return true;
  }else{
    const enteredPassword = prompt('This test is password protected,  Please enter the password')
    if(test.password == enteredPassword){
      return true;
    }else if(enteredPassword === null){
      return false;
    }else{
      const sendemail = Alert.confirm(" Error: Wrong password.\n Do you want to recover your password ?");
      if(sendemail){
        submitEmail(test);
      }
    }
  }
}

function submitEmail(test){
       
  console.log("starting process to send email");
  var emailBody = `
    <b>Test:</b> ${test.title}<br>
    <b>Password:</b> ${test.password}<br>`;

  var request = {
     email:  test.email,
     subject: "Teacher's Pet Online Password Recovery ",
     message: emailBody
  }
  axios({
    method: "POST", 
    url: "/sendEmail", 
    data: request
  }).then((response)=>{
    if (response.data.status === 'success'){
        alert("An email has been sent with your password."); 
        console.log("Email Sent.");
        
    }else if(response.data.status === 'fail'){
        alert("Email failed to send.")
        console.log("Email failed to send.");
    }
  })
}
export default validateTestPassWord;
