import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Alert from '../modules/Alert';

class FileUploader extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (!e.target.files[0]) return;
    if (this.props.fileLimit && e.target.files[0].size > this.props.fileLimit) {
      Alert.alert('That file is too big');
      return;
    }
    const imageFile = e.target.files[0];
    if (this.props.imgExtension.every(type => imageFile.type !== type)) {
      Alert.alert(imageFile.type + ' is not a supported format.');
      e.target.value = null;
      return ;
    }
    
    const reader = new FileReader();
    if (this.props.json) {
      reader.readAsText(e.target.files[0]);
    } else {
      reader.readAsDataURL(e.target.files[0]);
    }
    reader.onload = () => {
      this.props.onUpload(reader.result);
    };
    reader.onerror = (error) => {
      Alert.alert(`Could not upload file: ${error.message}`);
    };
  }

  render() {
    return (
      <View>
        <input
          aria-label={this.props.accessibilityLabel}
          name="file"
          type="file"
          accept={this.props.accept}
          onChange={this.handleChange}
        />
      </View>
    );
  }
}

FileUploader.propTypes = {
  accessibilityLabel: PropTypes.string,
  fileLimit: PropTypes.number,
  imgExtension: PropTypes.array,
  json: PropTypes.bool,
  accept:PropTypes.array,
  onUpload: PropTypes.func.isRequired,
};

export default FileUploader;
