import * as React from 'react';
import { Button as RButton } from 'react-native-paper';
import { Text } from 'react-native';

function Button(props) {
  return (
    <RButton {...props}>
      <Text style={{ color: '#000000' }}>{props.children}</Text>
    </RButton>
  );
}

export { Button };
