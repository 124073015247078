import * as Spacing from './Spacing';

let base = {
  padding: Spacing.base,
  marginBottom: 100,
};

let textFieldContainer = {
  flexDirection: 'row',
  margin: Spacing.base,
};

let label = {
  margin: Spacing.base,
  width: '30%',
};

export { base, textFieldContainer, label };
