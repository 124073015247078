import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Switch } from 'react-native';

class TrueFalseAnswerCreator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      answer: props.initialAnswerContainer.answer || false,
    };
    props.onChange(this.state);
  }
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.switchContainer}>
          <Text>True: </Text>
          <Switch
            value={this.state.answer}
            onValueChange={(value) =>
              this.setState({ answer: value }, () =>
                this.props.onChange(this.state)
              )
            }
            accessibilityLabel="True"
          />
        </View>
        <View style={styles.switchContainer}>
          <Text>False: </Text>
          <Switch
            value={!this.state.answer}
            onValueChange={(value) =>
              this.setState({ answer: !value }, () =>
                this.props.onChange(this.state)
              )
            }
            accessibilityLabel="False"
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  label: {
    marginHorizontal: 5,
  },
  switchContainer: {
    flexDirection: 'row',
  },
});

TrueFalseAnswerCreator.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialAnswerContainer: PropTypes.object.isRequired,
};

export default TrueFalseAnswerCreator;
