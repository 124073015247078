import * as Colors from './Colors';

let thin = {
  borderWidth: 1,
  borderColor: Colors.gray,
};

let rounded = {
  borderRadius: 5,
};

let thinRound = {
  ...thin,
  ...rounded,
};

let shadow = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.2,
  shadowRadius: 1.41,

  elevation: 2,
};

export { thin, rounded, thinRound, shadow };
