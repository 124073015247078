import React from 'react';
import { View } from 'react-native';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import Home from './Home';
import Welcome from './Welcome';
import SendPageView from '../modules/GoogleAnalitycs';
import { useIsFocused } from '@react-navigation/native';
function HomeScreen() {

  if(getCookieConsentValue('TeachersPetCookieConsent')){
    if(useIsFocused()){
      setTimeout(function(){SendPageView("Home");}, 500);
    }
    return (
      <View>
        <Home/>
      </View>
    );
  }else{
    setTimeout(function(){SendPageView("Welcome");}, 500);
    return (
    <View>
        <Welcome/>
    </View>
    );
  }
}

export default HomeScreen;
