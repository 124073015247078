import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { Borders, Spacing } from '../styles';
import MultipleChoiceAnswerCreator from './MultipleChoiceAnswerCreator';
import TrueFalseAnswerCreator from './TrueFalseAnswerCreator';
import FillInTheBlankAnswerCreator from './FillInTheBlankAnswerCreator';
import MatchingAnswerCreator from './MatchingAnswerCreator';
import { questionTypes } from '../modules/QuestionTypes';

function AnswerCreator(props) {
  let el;
  switch (props.type) {
    case questionTypes.multipleChoice:
      el = (
        <MultipleChoiceAnswerCreator
          initialAnswerContainer={props.initialAnswerContainer}
          onChange={props.onChange}
        />
      );
      break;
    case questionTypes.fillInTheBlank:
      el = (
        <FillInTheBlankAnswerCreator
          initialAnswerContainer={props.initialAnswerContainer}
          onChange={props.onChange}
        />
      );
      break;
    case questionTypes.trueFalse:
      el = (
        <TrueFalseAnswerCreator
          initialAnswerContainer={props.initialAnswerContainer}
          onChange={props.onChange}
        />
      );
      break;
    case questionTypes.essay:
      el = <Text accessible>Text field for answer will show up in test</Text>;
      break;
    case questionTypes.matching:
      el = (
        <MatchingAnswerCreator
          initialAnswerContainer={props.initialAnswerContainer}
          onChange={props.onChange}
        />
      );
      break;
    default:
      el = null;
      break;
  }

  return (
    <View style={[{ padding: Spacing.base }, Borders.thinRound]}>{el}</View>
  );
}

AnswerCreator.propTypes = {
  type: PropTypes.oneOf(Object.values(questionTypes)).isRequired,
  initialAnswerContainer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AnswerCreator;
