import React from 'react';

const RadioGroupContext = React.createContext();

class RadioButtonGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <RadioGroupContext.Provider
        value={{
          value: this.props.value,
          name: this.props.name,
          onChange: this.props.onValueChange,
        }}
      >
        {this.props.children}
      </RadioGroupContext.Provider>
    );
  }
}
class RadioButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.context) {
      let onChange = () => {
        this.context.onChange(this.props.value);
      };
      return (
        <input
          type="radio"
          value={this.props.value}
          checked={this.context.value === this.props.value}
          onChange={onChange}
          accessibilitylabel={this.props.accessibilityLabel}
        />
      );
    } else {
      return (
        <input
          type="radio"
          value={this.props.value}
          checked={this.props.status === 'checked'}
          onChange={() => this.props.onPress()}
          accessibilitylabel={this.props.accessibilitylabel}
        />
      );
    }
  }
}
RadioButton.contextType = RadioGroupContext;
RadioButton.Group = RadioButtonGroup;

export { RadioButton };
