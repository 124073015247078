import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Switch } from 'react-native';

class TrueFalseAnswerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstClick: typeof this.props.currentAnswer !== 'boolean' && true,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.state.firstClick && this.setState({ firstClick: false });
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.switchContainer}>
          <Text>True: </Text>
          <Switch
            value={this.props.currentAnswer}
            onValueChange={(value) => {
              this.handleClick();
              this.props.onChange(value);
            }}
            accessibilityLabel="True"
          />
        </View>
        <View style={styles.switchContainer}>
          <Text>False: </Text>
          <Switch
            value={!this.state.firstClick && !this.props.currentAnswer}
            accessibilityLabel="False"
            onValueChange={(value) => {
              this.handleClick();
              this.props.onChange(!value);
            }}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  label: {
    marginHorizontal: 5,
  },
  switchContainer: {
    flexDirection: 'row',
  },
});

TrueFalseAnswerView.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentAnswer: PropTypes.bool,
};

export default TrueFalseAnswerView;
