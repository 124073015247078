import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View, Linking } from 'react-native';
import { Button } from './Button';
import { Table, Row } from './Table';
import { Typography, Colors, Buttons, Spacing, Containers, Borders } from '../styles';
import { questionTypes } from '../modules/QuestionTypes';
import axios from 'axios';
import { TextInput } from './TextInput';
import SendPageView from '../modules/GoogleAnalitycs';


class GradeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      email: this.props.route.params.test.email,
      emailEditable:true,
      buttonDisabled:false,
      buttonSendEmailText:"EMAIL RESULTS"
    }
  }
  
  gradeQuestion(question, answer) {
    let result = {};
    if (answer === undefined) {
      result.correct = false;
      result.yourAnswer = 'Blank';
    } else {
      switch (question.type) {
        case questionTypes.multipleChoice:
          result.correct = question.answerContainer.answer === answer;
          result.yourAnswer = question.answerContainer.choices[answer];
          break;
        case questionTypes.fillInTheBlank:
          result.yourAnswer = answer;
          result.correct = question.answerContainer.answers
            .map((item) => {
              return item.toLowerCase();
            })
            .includes(answer.toLowerCase());
          break;
        case questionTypes.trueFalse:
          result.correct = question.answerContainer.answer === answer;
          result.yourAnswer = answer;
          break;
        case questionTypes.matching:
          result.correct = true;
          for (let i = 0; i < answer.answers.length; i++) {
            if (
              answer.switchedDefinitions[answer.answers[i]] ===
              question.answerContainer.terms[i].definition
            ) {
              continue;
            }
            result.correct = false;
            break;
          }

          result.yourAnswer = '';
          for (let i = 0; i < question.answerContainer.terms.length; i++) {
            result.yourAnswer +=
              question.answerContainer.terms[i].term +
              ' - ' +
              answer.switchedDefinitions[answer.answers[i]] +
              ',';
          }
          break;
        case questionTypes.essay:
          result.correct = true;
          result.yourAnswer = answer;
          break;
        default:
          result.correct = false;
          result.yourAnswer = answer;
      }
    }
    switch (question.type) {
      case questionTypes.multipleChoice:
        result.answer =
          question.answerContainer.choices[question.answerContainer.answer];
        break;
      case questionTypes.fillInTheBlank:
        result.answer = question.answerContainer.answers;
        break;
      case questionTypes.trueFalse:
        result.answer = question.answerContainer.answer;
        break;
      case questionTypes.matching:
        result.answer = '';
        for (let i = 0; i < question.answerContainer.terms.length; i++) {
          result.answer +=
            question.answerContainer.terms[i].term +
            ' - ' +
            question.answerContainer.terms[i].definition +
            ',';
        }
        break;
      case questionTypes.essay:
        result.answer = 'Multiple answers';
        break;
      default:
        result.answer = '';
    }
    return result;
  }
  onIdChange(event) {
    this.setState({id: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  submitEmail(e,gradeString, resultsTableData){
    this.setState({buttonDisabled: true})
    var testerId = this.state.id;
    if(!this.state.email){
      alert("Email Required.");
      this.setState({buttonDisabled: false})
    }else if(!testerId){
      alert("Tester ID Required.");
      this.setState({buttonDisabled: false})
    }else{
      this.setState({buttonSendEmailText: "SENDING EMAIL..."})
    console.log("starting process to send email");
    var emailBody = `
      <b>Tester ID:</b> ${testerId}<br>
      <b>Test: </b> ${this.props.route.params.test.title}<br>
      <b>Grade:</b> ${gradeString}<br>
      <table style="border: 1px solid black">
        ${resultsTableData.map(
          (value, index) => 
            '<tr>' + resultsTableData[index].map((value) => '<td style="border: 1px solid black">' + value + '</td>').join('') +'</tr>'
        ).join('')
    }`;

    var request = {
       email:  this.state.email,
       subject: 'Test Results from '+ testerId,
       message: emailBody
    }
    axios({
      method: "POST", 
      url: "/sendEmail", 
      data: request
    }).then((response)=>{
      if (response.data.status === 'success'){
          alert("Email Sent."); 
          console.log("Email Sent.");
          this.setState({id: ''});
          if(this.state.emailEditable){
            this.setState({email: ''});
          }
      }else if(response.data.status === 'fail'){
          alert("Email failed to send.")
          console.log("Email failed to send.");
      }
      this.setState({buttonDisabled: false})
      this.setState({buttonSendEmailText: "EMAIL RESULTS"})
    })
  }
}

componentDidMount() {
  if(!!this.props.route.params.test.email){
    this.setState({emailEditable: false})
  }
  setTimeout(function(){SendPageView();}, 500)
}

  render() {
    let results = [];
    let pointsCorrect = 0;
    let totalPoints = 0;

    const questions = this.props.route.params.questions;
    for (let i = 0; i < questions.length; i++) {
      totalPoints += questions[i].weight;
      let { correct, answer, yourAnswer } = this.gradeQuestion(
        questions[i],
        this.props.route.params.answerList[i]
      );
      pointsCorrect += correct ? questions[i].weight : 0;
      results.push({
        text: questions[i].text,
        explanation: questions[i].explanation,
        correct,
        answer,
        yourAnswer,
        weight: questions[i].weight,
        key: questions[i].id,
      });
    }

    let gradeString = `${pointsCorrect} earned out of ${totalPoints} possible points - ${Math.round(
      (pointsCorrect / totalPoints) * 100
    )}%`;

    let resultsTableData = [];
    let resultsTable = [];
    for (let i = 0; i < results.length; i++) {
      resultsTableData.push([
        results[i].correct.toString(),
        i + 1,
        results[i].text,
        results[i].answer.toString(),
        results[i].yourAnswer.toString(),
        results[i].explanation,
        results[i].weight,
      ]);
      resultsTable.push(
        <Row
          textStyle={styles.row}
          style={{
            backgroundColor: results[i].correct ? '#D0F0C0' : '	#FFC0CB',
          }}
          data={resultsTableData[i]}
          key={results[i].key}
        />
      );
    }
    
    resultsTableData.unshift([
      'Correct?',
      'Number',
      'Question',
      'Correct Answer',
      'Your Answer',
      'Explanation',
      'Weight',
    ]);

   
    return (
      <View style={Containers.base}>
        <Text accessibilityRole="header" accessible style={styles.headerText}>
          <Text style={{ fontWeight: 'bold' }}>Test: </Text>
          {this.props.route.params.test.title}
        </Text>
        <Text accessible style={styles.headerText}>
          <Text style={{ fontWeight: 'bold' }}>Grade: </Text>
          {gradeString}
        </Text>
        <Table borderStyle={{ borderWidth: 1, borderColor: '#222' }}>
          <Row textStyle={styles.row} header data={resultsTableData[0]} />
          {resultsTable}
        </Table>
        <br></br>
        
          <View style={[
            styles.container,
            Containers.base,
            Borders.thinRound,
            { marginBottom: Spacing.base },
          ]}>
            <Text accessible style={styles.headerText}>Email: </Text>
            <TextInput 
              editable = {this.state.emailEditable}
              value={this.state.email} 
              onChange={this.onEmailChange.bind(this)}
            ></TextInput>
            <Text accessible style={styles.headerText}>Tester ID: </Text>
            <TextInput
              //placeholder="Tester ID"
              value={this.state.id} 
              onChange={this.onIdChange.bind(this)}
            >
            </TextInput>
            <Button
              onPress={() => {this.submitEmail(this, gradeString, resultsTableData)}}
              style={[
                Buttons.base,
                //Spacing.baseStyle,
                this.state.buttonDisabled ? styles.buttonDisabled : styles.buttonEnabled,
              ]}
              mode="contained"
              disabled={this.state.buttonDisabled}
            >
              {this.state.buttonSendEmailText}
            </Button>
          </View>
        
        <View style={styles.container}>
          <Button
            onPress={() => {
              this.props.navigation.navigate('Home');
            }}
            style={[
              Buttons.base,
              //Spacing.baseStyle,
              { backgroundColor: Colors.blue },
            ]}
            mode="contained"
          >
        
          Home
        </Button>
      </View>
    </View>
    );
  }
}

const styles = StyleSheet.create({
  headerText: { 
    ...Typography.medium, 
    marginBottom: Spacing.base, 
    paddingTop:10,
    padding:5,
  },
  buttonEnabled: {
    backgroundColor: Colors.green,
  },
  buttonDisabled: {
    backgroundColor: Colors.gray,
  },
  container: {
    padding: 10,
    marginTop:10
  },
});

GradeScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default GradeScreen;
