import AsyncStorage from './AsyncStorage';
import 'react-native-get-random-values';
import base64 from 'react-native-base64';

class TestStorage {
  static async importTest(test, overwrite) {
    const jsonValue = JSON.stringify(test);
    const preexisting = await AsyncStorage.getItem('tp_' + test.title);
    if (preexisting && !overwrite) {
      throw new Error('a test already exists with that name');
    }
    return AsyncStorage.setItem('tp_' + test.title, base64.encode(jsonValue));
  }

  static async deleteTest(test) {
    try {
    await AsyncStorage.removeItem('tp_' + test.title);
  } catch (err) {
    console.error(err);
    throw err;
  }
   }

  static async exportTest(title, defaultTest) {
    if (defaultTest) {
      try {
        const tests = (await import('../tests/tests')).default;
        const test = (await import('../tests/' + tests[title])).default;
        return JSON.parse(base64.decode(test));
      } catch (err) {
        console.error(err);
        throw err;
      }
    } else {
      const jsonValue = base64.decode(
        await AsyncStorage.getItem('tp_' + title)
      );
      let test = jsonValue !== null ? JSON.parse(jsonValue) : null;
      return test;
    }
  }

  static async getUserTests() {
    const allKeys = await AsyncStorage.getAllKeys();
    return allKeys.reduce((acc, value) => {
      if (value.substring(0, 3) === 'tp_') {
        acc.push(value.substring(3, value.length));
      }
      return acc;
    }, []);
  }

  static async getDefaultTests() {
    try {
      const tests = (await import('../tests/tests')).default;
      return Object.keys(tests);
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}

export default TestStorage;
