import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { TextInput } from './TextInput';

function FillInTheBlankAnswerView(props) {
  return (
    <View>
      <TextInput
        accessibilityLabel="Enter answer"
        placeholder="Answer"
        onChangeText={props.onChange}
        value={props.currentAnswer || ''}
      />
    </View>
  );
}

FillInTheBlankAnswerView.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentAnswer: PropTypes.string,
};

export default FillInTheBlankAnswerView;
