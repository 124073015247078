import React from 'react';
import PropTypes from 'prop-types';
import { Image, View, ActivityIndicator } from 'react-native';
import Alert from '../modules/Alert';

class AsyncImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      height: 0,
      width: 0,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  async componentDidMount() {
    try {
      let { width, height } = await this.imageDimensions(this.props.source);
      if (width > height) {
        if (width > this.props.maxWidth) {
          height = (height * this.props.maxWidth) / width;
          width = this.props.maxWidth;
        }
      } else {
        if (height > this.props.maxHeight) {
          width = (width * this.props.maxHeight) / height;
          height = this.props.maxHeight;
        }
      }
      this.setState({ width, height });
    } catch (e) {
      Alert.alert('There was an error displaying an image');
      console.error('There was an error displaying an image: '+ e);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async imageDimensions(uri) {
    return new Promise((resolve, reject) => {
      Image.getSize(
        uri,
        (width, height) => {
          resolve({ width, height });
        },
        (error) => {
          console.log('There was an error with image dimensions: '+error);
          reject(error);
        }
      );
    });
  }

  render() {
    return (
      <View>
        {this.state.isLoading ? (
          <View
            style={{
              width: this.props.maxWidth,
              height: this.props.maxHeight,
            }}
          >
            <ActivityIndicator />
          </View>
        ) : (
          <Image
            source={{ uri: this.props.source, cache: 'reload' }}
            style={{
              width: this.state.width,
              height: this.state.height,
            }}
            accessible
            accessibilityLabel={this.props.accessibilityLabel}
          />
        )}
      </View>
    );
  }
}

AsyncImage.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
  accessibilityLabel: PropTypes.string,
};

export default AsyncImage;
