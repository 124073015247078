import * as React from 'react';
import { TextInput as RTextInput } from 'react-native-paper';
import { Borders } from '../styles';

function TextInput(props) {
  return (
    <RTextInput
      {...props}
      style={{ backgroundColor: '#ffffff', ...Borders.thinRound }}
    />
  );
}

export { TextInput };
