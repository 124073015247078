import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native';
import { TextInput } from './TextInput';
import { Button } from './Button';
import { v4 as uuidv4 } from 'uuid';

class MatchingAnswerCreator extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      terms:
        (props.initialAnswerContainer.terms &&
          props.initialAnswerContainer.terms.length) > 0
          ? props.initialAnswerContainer.terms
          : [{}],
    };
    props.onChange(this.state);

    this.ids = [];

    this.handleNewChoice = this.handleNewChoice.bind(this);
    this.handleDeleteChoice = this.handleDeleteChoice.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleDefinitionChange = this.handleDefinitionChange.bind(this);
  }

  handleTermChange(i, term) {
    let newTerms = this.state.terms;
    newTerms[i].term = term;
    this.setState({ terms: newTerms }, () => this.props.onChange(this.state));
  }

  handleDefinitionChange(i, definition) {
    let newTerms = this.state.terms;
    newTerms[i].definition = definition;
    this.setState({ terms: newTerms }, () => this.props.onChange(this.state));
  }

  handleNewChoice(i) {
    let newTerms = this.state.terms;
    newTerms.splice(i + 1, 0, {});
    this.ids.splice(i + 1, 0, uuidv4());
    this.setState({ terms: newTerms }, () => this.props.onChange(this.state));
  }

  handleDeleteChoice(i) {
    let newTerms = this.state.terms;
    if (newTerms.length > 1) {
      newTerms.splice(i, 1);
      this.ids.splice(i, 1);
    }
    this.setState({ terms: newTerms }, () => this.props.onChange(this.state));
  }

  render() {
    let answerInputs = [];
    for (let i = 0; i < this.state.terms.length; i++) {
      if (!this.ids[i]) {
        this.ids[i] = uuidv4();
      }
      answerInputs.push(
        <View key={this.ids[i]} style={styles.answerInput}>
          <TextInput
            placeholder={`Enter term ${i + 1} here...`}
            accessibilityLabel={`Term ${i + 1}`}
            style={styles.answerTextInput}
            onChangeText={((i) => {
              return (term) => {
                this.handleTermChange(i, term);
              };
            })(i)}
            value={this.state.terms[i].term || ''}
          />
          <Text style={{ marginHorizontal: 5, alignSelf: 'center' }} accessible>
            goes with
          </Text>
          <TextInput
            placeholder={`Enter definition ${i + 1} here...`}
            accessibilityLabel={`Definition ${i + 1}`}
            style={styles.answerTextInput}
            onChangeText={((i) => {
              return (definition) => {
                this.handleDefinitionChange(i, definition);
              };
            })(i)}
            value={this.state.terms[i].definition || ''}
          />
          <View style={styles.actionButton}>
            <Button
              accessibilityLabel="Add new answer choice"
              onPress={((i) => {
                return () => {
                  this.handleNewChoice(i);
                };
              })(i)}
              mode="contained"
            >
              +
            </Button>
          </View>
          <View style={styles.actionButton}>
            <Button
              accessibilityLabel="Delete answer choice"
              onPress={((i) => {
                return () => {
                  this.handleDeleteChoice(i);
                };
              })(i)}
              mode="contained"
            >
              X
            </Button>
          </View>
        </View>
      );
    }

    return <View>{answerInputs}</View>;
  }
}

const styles = StyleSheet.create({
  answerInput: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 5,
  },
  answerTextInput: {
    flex: 3,
  },
  actionButton: {
    flex: 1,
    alignSelf: 'center',
    marginHorizontal: 5,
  },
});

MatchingAnswerCreator.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialAnswerContainer: PropTypes.object.isRequired,
};

export default MatchingAnswerCreator;
