import React from "react";
import { Colors, Typography } from '../styles';
import LogoImg from '../images/logo-white.svg';
const space = " ";
const Footer = () => (
    <div style={ footerStyle }>
      <div>
        <img tabIndex="0" style={ logo  } alt="APH logo" src={LogoImg} />
      </div>
      <div>
        <p style={ text  }> Version: 1.30 </p>
      </div>
      <div>
        <p style={ text  }> &emsp;|&emsp;</p>
      </div>
      <div>
        <p tabIndex="0" style={ text  }>             
          <a style={link} target="_blank"  href="https://tech.aph.org/tp/" > User Guide</a>
        </p>
      </div>
    </div>
);

const footerStyle = {
      backgroundColor:Colors.background,
      color:Colors.white,
      width: '100%',
      bottom:0,
      position: 'fixed',
      padding:5,
      display: 'flex',
      flexdirection: 'row',
      justifyContent: 'center'
};
const text = {
  fontFamily: 'Arial',
  display: 'flex',
  justifyContent: 'center',
  fontSize:12,
  fontWeight: "bold",
  //padding:5,
};
const link = {
  textDecoration: 'none',
  color: Colors.white,
  
};
const logo = {
  width: 30,
  padding:5,
  marginRight:10,
  //height:'30'
};

export default Footer;
