import React from 'react';
import { View, Text, findNodeHandle } from 'react-native';
import { Button } from './Button';
import { Buttons, Spacing, Borders, Containers } from '../styles';
import TestStorage from '../modules/TestStorage';
import Alert from '../modules/Alert';
import validateTestSchema from '../modules/TestSchema';
import downloadJson from '../modules/FileDownloader';
import AsyncTestPicker from './AsyncTestPicker';
import FileUploader from './FileUploader';
import base64 from 'react-native-base64';
import SendPageView from '../modules/GoogleAnalitycs';


class ImportExportScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTest: 0,
      testList: [],
      testToImport: {},
    };

    this.handleExport = this.handleExport.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }

  componentDidMount(){
    setTimeout(function(){SendPageView();}, 500);
    findNodeHandle(this.exportText).focus();
  }
  
  async handleExport() {
    const test = await TestStorage.exportTest(
      this.state.testList[this.state.selectedTest]
    );
    downloadJson(test);
  }

  async handleImport() {
    try {
      validateTestSchema(this.state.testToImport);
    } catch (e) {
      Alert.alert('You have an incorrect test file');
      console.error('Incorrect test file:'+ e);
      return;
    }

    try {
      await TestStorage.importTest(this.state.testToImport, false);
      Alert.alert(
        'Test imported successfully with title: ' +
          this.state.testToImport.title
      );
    } catch (e) {
      const choice = Alert.confirm(
        'A test already exists with this name. Overwrite it?'
      );
      if (choice) {
        await TestStorage.importTest(this.state.testToImport, true);
        Alert.alert(
          'Test imported successfully with title: ' +
            this.state.testToImport.title
        );
      }
    }
  }

  handleUpload(test) {
    try {
      this.setState({ testToImport: JSON.parse(base64.decode(test)) });
    } catch (e) {
      Alert.alert('Invalid test file');
      console.error(e);
    }
  }

  render() {
    return (
      <View style={Containers.base}>
        <View
          style={[
            Containers.base,
            Borders.thinRound,
            { marginBottom: Spacing.base },
          ]}
        >
          <Text 
          ref={(input) => {
            this.exportText = input;
          }}  
          accessible>
            Choose a test to export:</Text>
          <AsyncTestPicker
            onSelectionChange={(selectedTest) =>
              this.setState({ selectedTest })
            }
            onTestListChange={(testList) => this.setState({ testList })}
            selectedTest={this.state.selectedTest}
            aria-label="Choose a test to export"
            testList={this.state.testList}
          />
          <Button
            mode="contained"
            onPress={this.handleExport}
            style={[Buttons.base, Spacing.baseStyle]}
          >
            Export
          </Button>
        </View>
        <View style={[Containers.base, Borders.thinRound]}>
          <FileUploader
            onUpload={this.handleUpload}
            imgExtension={['application/json']}
            accept={['.json']}
            json
            accessibilityLabel="Choose test file to import"
          />
          <Button
            mode="contained"
            onPress={this.handleImport}
            style={[Buttons.base, Spacing.baseStyle]}
          >
            Import a test
          </Button>
        </View>
      </View>
    );
  }
}

export default ImportExportScreen;
