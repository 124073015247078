import React from 'react';
import { View, Text, StyleSheet, findNodeHandle } from 'react-native';
import { Button } from './Button';
import { Buttons, Spacing, Borders, Containers, Typography, Colors } from '../styles';
import axios from 'axios';
import { TextInput } from './TextInput';
import SendPageView from '../modules/GoogleAnalitycs';

class ContactUsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      type:'',
      description: '',
      buttonSendText:"SUBMIT",
      destinationMail:"technology@aph.org"
      //destinationMail:"lvaldes@aph.org"
    };
  }

  componentDidMount(){
    setTimeout(function(){SendPageView();}, 500);
    findNodeHandle(this.nameText).focus();
  }
  
  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  onTypeChange(event) {
    this.setState({type: event.target.value})
  }
  onSubjectChange(event) {
    this.setState({subject: event.target.value})
  }
  onDescriptionChange(event) {
    this.setState({description: event.target.value})
  }

  submitEmail(){
    this.setState({buttonDisabled: true})
    var name = this.state.name;
    var email = this.state.email;
    var description = this.state.description;
    var type = this.state.type;
    var subject = this.state.subject;
    if(!name){
      alert("Name Required.");
      this.setState({buttonDisabled: false})
    }else if(!email){
      alert("Email Address Required.");
      this.setState({buttonDisabled: false})
    }else if(!type){
      alert("Type Required.");
      this.setState({buttonDisabled: false})
    }else if(!subject){
      alert("Subject Required.");
      this.setState({buttonDisabled: false})
    }else if(!description){
      alert("Description Required.");
      this.setState({buttonDisabled: false})
    }else{
      this.setState({buttonSendText: "SENDING..."})
    console.log("starting process to send email");
    var emailBody = `
      <b>User Name:</b> ${name}<br>
      <b>User Email:</b> ${email}<br>
      <b>Feedback Type:</b> ${type}<br>
      <b>Subject:</b> ${subject}<br>
      <b>Description: </b> ${description}<br>`;

    var request = {
       email:  this.state.destinationMail,
       subject: "Teacher's Pet Online Feedback ",
       message: emailBody
    }
    axios({
      method: "POST", 
      url: "/sendEmail", 
      data: request
    }).then((response)=>{
      if (response.data.status === 'success'){
          alert("Thank you! We really appreciate your feedback."); 
          console.log("Email Sent.");
          this.setState({name: ''});
          this.setState({email: ''});
          this.setState({type: ''});
          this.setState({subject: ''});
          this.setState({description: ''});
      }else if(response.data.status === 'fail'){
          alert("Feedback failed to send.")
          console.log("Email failed to send.");
      }
      this.setState({buttonDisabled: false})
      this.setState({buttonSendText: "SUBMIT"})
    })
  }
}

  render() {
    return (
      <View style={Containers.base}>
        <View
          style={[
            Containers.base,
            Borders.thinRound,
            { marginBottom: Spacing.base },
          ]}
        >
          <Text  
          ref={(input) => {
            this.nameText = input;
          }} 
          accessible 
          style={styles.headerText}>Name:</Text>
          <TextInput
              //placeholder="Name"
              value={this.state.name} 
              onChange={this.onNameChange.bind(this)}
              accessibilitylabel="Name"
              aria-label="Name"
              aria-required="true"
            >
          </TextInput>
          <Text accessible style={styles.headerText}>Email:</Text>
          <TextInput
              //placeholder="Email"
              value={this.state.email} 
              onChange={this.onEmailChange.bind(this)}
              accessibilitylabel="Email"
              aria-label="Email"
              aria-required="true"
            >
          </TextInput>
          <Text accessible style={styles.headerText}>Please select the type of feedback:</Text>
          <select 
          style={{height: 30 + 'px',backgroundColor: '#ffffff', ...Borders.thinRound}}
          value={this.state.type} 
          onChange={this.onTypeChange.bind(this)} 
          accessibilitylabel="Feedback Type"
          aria-label="Feedback Type"
          aria-required="true"> 
              
            <option value="">Please select one</option>       
            <option value="Bug">Bug</option>
            <option value="Feature Request">Feature Request</option>
            <option value="Other">Other</option>
          </select>
          <Text accessible style={styles.headerText}>Subject:</Text>
          <TextInput
              //placeholder="Subject"
              value={this.state.subject} 
              onChange={this.onSubjectChange.bind(this)}
              accessibilitylabel="Subject"  
              aria-label="Subject"
              aria-required="true" 
            >
          </TextInput>
          <Text accessible  style={styles.headerText}>Description:</Text>
          <TextInput 
              //placeholder="Description"
              multiline = {true}
              numberOfLines = {5}
              value={this.state.description}
              onChange={this.onDescriptionChange.bind(this)}
              accessibilitylabel="Description" 
              aria-label="Description"
              aria-required="true" 
            >
          </TextInput>
          <Button
              onPress={() => {this.submitEmail()}}
              style={[
                Buttons.base,
                //Spacing.baseStyle,
                this.state.buttonDisabled ? styles.buttonDisabled : styles.buttonEnabled,
              ]}
              mode="contained"
              disabled={this.state.buttonDisabled}
            >
              {this.state.buttonSendText}
            </Button>
        </View>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  headerText: { 
    ...Typography.medium, 
    marginBottom: Spacing.base, 
    paddingTop:10,
    padding:5,
  },
  buttonEnabled: {
    backgroundColor: Colors.red,
  },
  buttonDisabled: {
    backgroundColor: Colors.gray,
  },
  container: {
    padding: 10,
  },
});

const text = {
  paddingTop:15,
  padding:5,
};

export default ContactUsScreen;
