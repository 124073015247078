export const questionTypes = {
  multipleChoice: 'multipleChoice',
  fillInTheBlank: 'fillInTheBlank',
  trueFalse: 'trueFalse',
  essay: 'essay',
  matching: 'matching',
};

export const questionNames = {
  multipleChoice: 'Multiple Choice',
  fillInTheBlank: 'Fill in the Blank',
  trueFalse: 'True or False',
  essay: 'Essay',
  matching: 'Matching',
};
