import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native';
import { TextInput } from './TextInput';
import { Containers, Typography, Colors, Borders, Spacing } from '../styles';
import shuffle from 'fisher-yates';
import alphabet from 'alphabet';

class MatchingAnswerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      switchedDefinitions: shuffle(
        this.props.answerContainer.terms.map((item) => item.definition)
      ),
      answers: [],
    };

    this.handleAnswerInput = this.handleAnswerInput.bind(this);
  }

  handleAnswerInput(i, value) {
    let newAnswers = this.state.answers;
    newAnswers[i] = this._decodeMatchingLabel(value);
    this.setState({ answers: newAnswers }, () => {
      this.props.onChange(this.state);
    });
  }

  _encodeMatchingLabel(i) {
    let result = '';
    while (i >= 0) {
      result += alphabet.upper[i % alphabet.upper.length];
      i -= alphabet.upper.length;
    }

    return result;
  }

  _decodeMatchingLabel(string) {
    return (
      alphabet.upper.indexOf(string.toUpperCase()[0]) +
      alphabet.upper.length * (string.length - 1)
    );
  }

  render() {
    return (
      <View style={styles.answerLine}>
        <View>
          {this.props.answerContainer.terms.map((value, index) => (
            <View key={index} style={{ flexDirection: 'row' }}>
              <TextInput
                onChangeText={((i) => {
                  return (value) => {
                    this.handleAnswerInput(i, value);
                  };
                })(index)}
                placeholder="Enter letter"
              />
              <Text style={styles.textItem} accessible>
                {this.props.answerContainer.terms[index].term}
              </Text>
            </View>
          ))}
        </View>
        <View
          style={[
            Containers.base,
            Borders.thinRound,
            { backgroundColor: Colors.lightGray },
          ]}
        >
          {this.state.switchedDefinitions.map((value, index) => (
            <View key={index} style={{ flexDirection: 'row' }}>
              <Text
                accessible
                style={Typography.large}
                accessibilityLabel={`Definition ${this._encodeMatchingLabel(
                  index
                )}`}
              >
                {this._encodeMatchingLabel(index)}.
              </Text>
              <Text accessible style={Typography.large}>
                {this.state.switchedDefinitions[index]}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  answerLine: {
    flexDirection: 'row',
    marginBottom: Spacing.base,
    justifyContent: 'space-around',
  },
  textItem: {
    marginHorizontal: Spacing.base,
    ...Typography.medium,
    alignSelf: 'center',
  },
});

MatchingAnswerView.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentAnswer: PropTypes.object,
  answerContainer: PropTypes.object.isRequired,
};

export default MatchingAnswerView;
