import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Buttons, Spacing, Borders, Containers, Typography, Colors } from '../styles';

class Welcome extends React.Component {
  
  render() {
    return (
      <View style={Containers.base}>
        <View>
          <Text accessible style={styles.headerText}>Welcome to Teacher's Pet.</Text>
        </View>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  headerText: { 
    ...Typography.large, 
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: Spacing.base, 
    paddingTop:25,
    padding:5,
  },
  bodyText: { 
    ...Typography.medium, 
    textAlign: 'center',
    marginBottom: Spacing.base, 
    paddingTop:10,
    padding:5,
  },
  container: {
    padding: 10,
  },
});

const text = {
  paddingTop:15,
  padding:5,
};

export default Welcome;
