import { questionTypes } from './QuestionTypes';
import assert from 'assert';

function validateTestSchema(test) {
  if (typeof test.title !== 'string') {
    assert.fail('title');
  }
  if (typeof test.description !== 'string') {
    assert.fail('description');
  }
  if (typeof test.isTimed !== 'boolean') {
    assert.fail('isTimed');
  }
  if (typeof test.time !== 'number') {
    assert.fail('time');
  }
  if (typeof test.randomizeTest !== 'boolean') {
    assert.fail('randomizeTest');
  }
  if (typeof test.multipleChoice !== 'number') {
    assert.fail('multipleChoice');
  }
  if (typeof test.fillInTheBlank !== 'number') {
    assert.fail('fillInTheBlank');
  }
  if (typeof test.trueFalse !== 'number') {
    assert.fail('trueFalse');
  }
  if (typeof test.matching !== 'number') {
    assert.fail('matching');
  }
  if (typeof test.essay !== 'number') {
    assert.fail('essay');
  }
  if (!Array.isArray(test.questions)) {
    assert.fail('questions array');
  }

  for (let i = 0; i < test.questions.length; i++) {
    let question = `question ${i + 1} `;
    if (typeof test.questions[i].type !== 'string') {
      assert.fail(question + 'type');
    }
    if (typeof test.questions[i].weight !== 'number') {
      assert.fail(question + 'weight');
    }
    if (typeof test.questions[i].text !== 'string') {
      assert.fail(question + 'text');
    }
    if (typeof test.questions[i].id !== 'string') {
      assert.fail(question + 'id');
    }
    if (
      !(
        typeof test.questions[i].audio === 'string' ||
        test.questions[i].audio === null
      )
    ) {
      assert.fail(question + 'audio');
    }
    if (
      !(
        typeof test.questions[i].image === 'string' ||
        test.questions[i].image === null
      )
    ) {
      assert.fail(question + 'image');
    }
    if (typeof test.questions[i].explanation !== 'string') {
      assert.fail(question + 'explanation');
    }
    if (typeof test.questions[i].answerContainer === 'object') {
      switch (test.questions[i].type) {
        case questionTypes.essay:
          continue;
        case questionTypes.fillInTheBlank:
          if (!Array.isArray(test.questions[i].answerContainer.answers)) {
            assert.fail(question + 'answers');
          }
          break;
        case questionTypes.multipleChoice:
          if (
            !(
              Array.isArray(test.questions[i].answerContainer.choices) &&
              typeof test.questions[i].answerContainer.answer === 'number'
            )
          ) {
            assert.fail(question + 'choices or answer');
          }
          break;
        case questionTypes.trueFalse:
          if (typeof test.questions[i].answerContainer.answer !== 'boolean') {
            assert.fail(question + 'answer');
          }
          break;
      }
    } else {
      assert.fail(question + 'answer container');
    }
  }

  return true;
}

export default validateTestSchema;
