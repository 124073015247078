import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet } from 'react-native';
import { RadioButton } from './RadioButton';

function MultipleChoiceAnswerView(props) {
  return (
    <View>
      <RadioButton.Group
        value={props.currentAnswer}
        onValueChange={(i) => props.onChange(i)}
      >
        {props.answerContainer.choices.map((value, index) => (
          <View
            key={index}
            style={styles.answerLine}
            accessibilityRole="radiogroup"
          >
            <RadioButton
              value={index}
              accessibilityLabel={`Select ${value} as correct answer`}
            />
            <Text style={styles.choiceText}>{value}</Text>
          </View>
        ))}
      </RadioButton.Group>
    </View>
  );
}

const styles = StyleSheet.create({
  answerLine: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  choiceText: {
    marginLeft: 10,
  },
});

MultipleChoiceAnswerView.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentAnswer: PropTypes.number,
  answerContainer: PropTypes.object.isRequired,
};

export default MultipleChoiceAnswerView;
