let background = '#000000';
let foreground = '#FFFFFF';

let white = '#FFFFFF';
let black = '#000000';

let red = '#E33E60';
let blue = '#5CA0A7';
let gold = '#FE9600';
let purple = '#E0B0FF';
let gray = '#5B6670';
let lightGray = '#F5F5F5';
let green = '#3ed2b8';

export {
  background,
  foreground,
  white,
  black,
  red,
  blue,
  gold,
  purple,
  gray,
  lightGray,
  green
};
