import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  View,
  StyleSheet,
  ActivityIndicator,
  findNodeHandle,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import { Button } from './Button';
import { HotKeys } from 'react-hotkeys';
import { Typography, Spacing, Colors, Buttons } from '../styles';
import AnswerView from './AnswerView';
import AsyncImage from './AsyncImage';
import Sound from '../modules/Sound';
import Alert from '../modules/Alert';
import { red, white } from '../styles/Colors';


class QuestionView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      audioLoaded: false,
    };

    this.playSound = this.playSound.bind(this);
    this.stopSound = this.stopSound.bind(this);

    this.handlers = {
      PLAY: this.playSound,
      STOP: this.stopSound,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentDidMount() {
    findNodeHandle(this.questionText).focus();
    this.audio = new Sound(
      this.props.question.audio,
      Sound.MAIN_BUNDLE,
      (error) => {
        if (error) {
          Alert.alert('Failed to load the sound');
          console.error(error);
          return;
        }
        this.audio.setVolume(1);
        this.setState({ audioLoaded: true });
      }
    );
  }

  playSound() {
    this.audio.play();
  }

  stopSound() {
    this.audio.stop();
  }

  componentWillUnmount() {
    this.audio.stop();
  }

  render() {
    return (
      <HotKeys handlers={this.handlers}>
        <View style={styles.container}>
          {this.props.question.image ? (
            <AsyncImage
              source={this.props.question.image}
              maxWidth={200}
              maxHeight={200}
              accessibilityLabel="Image"
            />
          ) : null}
          <View style={styles.answerContainer}>
          <Text
              ref={(input) => {
                this.questionText = input;
              }}
              value={this.props.question.text}
              style={[Typography.medium, Spacing.baseStyle]}
              accessible
            >
              {this.props.question.text}
            
            {this.props.question.audio ? (
              this.state.audioLoaded ? (
                <View style={{ flexDirection: 'row' }}>
                  <Button
                  mode="contained"
                  icon="play"
                  accessibilityLabel="Play Sound"
                  aria-label="Play Sound"
                  onPress={this.playSound}
                  style={[
                    Buttons.base,
                    Spacing.baseStyle,
                    styles.buttonStart
                  ]}
                >
                  <span style={{ color: '#FFFFFF' }}>Play</span>
                  
                </Button>
                <Button
                  mode="contained"
                  icon="stop"
                  accessibilityLabel="Stop Sound"
                  aria-label="Stop Sound"
                  onPress={this.stopSound}
                  style={[
                    Buttons.base,
                    Spacing.baseStyle,
                    styles.buttonStop
                  ]}
                >
                  <span style={{ color: '#FFFFFF' }}>Stop</span>
                </Button>
              </View>
                
                
              ) : (
                <>
                  <ActivityIndicator />
                  <Text accessible>Audio is loading</Text>
                </>
              )
            ) : null}
        </Text>  
            <AnswerView
              type={this.props.question.type}
              onChange={this.props.onAnswerChange}
              disabled={this.props.review}
              answerContainer={this.props.question.answerContainer}
              currentAnswer={this.props.currentAnswer}
            />
          </View>
          {this.props.review ? (
            <Text>{this.props.question.explanation}</Text>
          ) : null}
        </View>
      </HotKeys>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#222',
    marginVertical: 10,
    padding: 10,
  },
  answerContainer: {
    flex: 1,
  },
  buttonStart: {
    backgroundColor: '#0b400b',
    borderRadius: 20,
    padding: 2,
    //width:120,
    marginBottom: 10,
    shadowColor: '#303030',
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    shadowOpacity: 0.35,
    
  },
  buttonStop: {
    backgroundColor: '#fa002a',
    borderRadius: 20,
    padding: 2,
    //width:150,
    marginBottom: 10,
    shadowColor: '#303030',
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 5,
    shadowOpacity: 0.35,
  },
});

QuestionView.propTypes = {
  question: PropTypes.object.isRequired,
  onAnswerChange: PropTypes.func.isRequired,
  review: PropTypes.bool,
  currentAnswer: PropTypes.any,
};

export default QuestionView;
