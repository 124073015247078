import React from 'react';
import { Spacing, Buttons, Containers, Typography, Colors } from '../styles';
import PropTypes from 'prop-types';
import { TextInput } from './TextInput';
import { Button } from './Button';
import {
  Text,
  Picker,
  View,
  StyleSheet,
  findNodeHandle,
  ViewPropTypes,
} from 'react-native';
import FileUploader from './FileUploader.web';
import AnswerCreator from './AnswerCreator';
import { questionTypes, questionNames } from '../modules/QuestionTypes';

class QuestionCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.initialQuestion.text || '',
      explanation: props.initialQuestion.explanation || '',
      answerContainer: props.initialQuestion.answerContainer || {},
      id: props.initialQuestion.id,
      type: props.initialQuestion.type || questionTypes.fillInTheBlank,
      weight: props.initialQuestion.weight || 1,
      image: props.initialQuestion.image || null,
      audio: props.initialQuestion.audio || null,
    };
    this.props.onChange(this.state);

    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleChangeExplanation = this.handleChangeExplanation.bind(this);
    this.handleChangeWeight = this.handleChangeWeight.bind(this);
    this.handleChangeAnswerContainer = this.handleChangeAnswerContainer.bind(
      this
    );
    this.handleDelete = this.handleDelete.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    for (let attribute in nextState) {
      if (!(attribute in this.state)) {
        return true;
      }
    }
    for (let attribute in this.state) {
      if (nextState[attribute] !== this.state[attribute]) {
        return true;
      }
    }
    return false;
  }

  handleChangeQuestion(text) {
    this.setState({ text }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeExplanation(text) {
    this.setState({ explanation: text }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeWeight(text) {
    let weight = parseInt(text);
    if (!weight || weight < 1) {
      weight = 1;
    }
    this.setState({ weight }, () => {
      this.props.onChange(this.state);
    });
  }

  handleChangeAnswerContainer(answerContainer) {
    this.setState({ answerContainer: answerContainer }, () => {
      this.props.onChange(this.state);
    });
  }

  handleDelete() {
    this.props.onDelete();
  }

  componentDidMount() {
    if (this.props.last) {
      findNodeHandle(this.number).focus();
    }
  }

  render() {
    return (
      <View
        style={[
          Containers.base,
          {
            borderBottomWidth: 1,
            borderBottomColor: Colors.gray,
          },
        ]}
      >
        <View style={styles.titleContainer}>
          <Text
            style={[
              Typography.medium,
              Spacing.baseStyle,
              { alignSelf: 'center' },
            ]}
            ref={(number) => {
              this.number = number;
            }}
            accessible
            accessibilityRole="header"
          >
            Question {this.props.questionNumber}.
          </Text>
          <Picker
            selectedValue={this.state.type}
            onValueChange={(value) =>
              this.setState({ type: value }, () =>
                this.props.onChange(this.state)
              )
            }
            accessibilityLabel="Question Type"
          >
            {Object.keys(questionTypes).map((value) => {
              return (
                <Picker.Item
                  key={value}
                  label={questionNames[value]}
                  value={value}
                />
              );
            })}
          </Picker>
        </View>
        <View style={styles.topLine}>
          <TextInput
            accessibilityLabel="Input question"
            placeholder={`Question ${this.props.questionNumber}`}
            onChangeText={this.handleChangeQuestion}
            value={this.state.text}
            style={[styles.questionField, styles.textField]}
          />
          <Button
            style={[Buttons.base, Spacing.baseStyle]}
            mode="contained"
            accessibilityLabel={`Delete Question ${this.props.questionNumber}`}
            onPress={this.handleDelete}
          >
            X
          </Button>
        </View>
        <View style={styles.answerCreatorContainer}>
          <AnswerCreator
            type={this.state.type}
            initialAnswerContainer={this.state.answerContainer}
            onChange={this.handleChangeAnswerContainer}
          />
        </View>
        <View style={styles.textFieldContainer}>
          <View style={Containers.textFieldContainer}>
            <Text style={[Containers.label, Typography.small]}>
              Answer explanation:{' '}
            </Text>
            <TextInput
              placeholder="Answer explanation"
              onChangeText={this.handleChangeExplanation}
              value={this.state.explanation}
              style={styles.textField}
            />
          </View>
          <View style={Containers.textFieldContainer}>
            <Text style={[Containers.label, Typography.small]}>
              Question Point Value:{' '}
            </Text>
            <TextInput
              keyboardType="numeric"
              onChangeText={this.handleChangeWeight}
              style={styles.textField}
              //placeholder="Question Weight"
              value={this.state.weight.toString()}
            />
          </View>
          <View style={Containers.textFieldContainer}>
            <Text style={[Containers.label, Typography.small]}>
              Image upload (optional):{' '}
            </Text>
            <FileUploader
              imgExtension={['image/png', 'image/jpeg', 'image/gif', 'image/bmp']}
              accept={['.png', '.jpeg', '.gif', '.bmp']}
              onUpload={(image) =>
                this.setState({ image }, () => this.props.onChange(this.state))
              }
              accessibilityLabel="Choose image file"
            />
          </View>
          <View style={Containers.textFieldContainer}>
            <Text style={[Containers.label, Typography.small]}>
              Audio upload (optional):{' '}
            </Text>
            <FileUploader
            imgExtension={['audio/wav']}
            accept={['.wav']}
              onUpload={(audio) => {
                this.setState({ audio }, () => this.props.onChange(this.state));
              }}
              accessibilityLabel="Choose audio file"
            />
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  topLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: Spacing.base,
    alignItems: 'center',
  },
  questionField: {
    flex: 5,
  },
  answerCreatorContainer: {
    padding: Spacing.base,
  },
  textFieldContainer: {
    padding: Spacing.base,
    marginVertical: Spacing.base,
  },
});

QuestionCreator.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  initialQuestion: PropTypes.object.isRequired,
  questionNumber: PropTypes.number.isRequired,
  style: ViewPropTypes.style,
};

export default QuestionCreator;
