import React from 'react';
import PropTypes from 'prop-types';

function Table(props) {
  return (
    <table style={{ display: 'flex', border: '1px solid #222', flex: 1 }}>
      <tbody style={{ width: '100%', overflow: 'scroll' }}>
        {props.children}
      </tbody>
    </table>
  );
}

function Row(props) {
  let cells = [];
  for (let i = 0; i < props.data.length; i++) {
    if (props.header) {
      cells.push(
        <th key={i} style={{ border: '1px solid #222', ...props.style }}>
          {props.data[i]}
        </th>
      );
    } else {
      cells.push(
        <td key={i} style={{ border: '1px solid #222', ...props.style }}>
          {props.data[i]}
        </td>
      );
    }
  }

  return <tr>{cells}</tr>;
}

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Row.propTypes = {
  header: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  style: PropTypes.object,
};

export { Table, Row };
