import React from 'react';
import PropTypes from 'prop-types';
import TestTaker from './TestTaker';
import { HotKeys } from 'react-hotkeys';

function TestTakingScreen(props) {
  const keyMap = {
    PLAY: ['MediaPlayPause', 'Play', 'ctrl+alt+p'],
    STOP: ['MediaPlayPause', 'Stop', 'ctrl+alt+s'],
  };
  function handleSubmit(answerList, questions) {
    props.navigation.navigate('Grade', {
      answerList,
      questions,
      test: props.route.params.test,
    });
  }

  return (
    <HotKeys keyMap={keyMap}>
      <TestTaker onSubmit={handleSubmit} test={props.route.params.test} />
    </HotKeys>
  );
}

TestTakingScreen.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default TestTakingScreen;
