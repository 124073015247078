import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Links, Spacing, Borders, Containers, Typography, Colors } from '../styles';
import { Link } from '@react-navigation/native';

class Home extends React.Component {
  
  render() {
    return (
      <View>
        <Link
          to="/SelectTest"
          style={[Links.link, { backgroundColor: Colors.blue }]}
        >
          Take a Test
        </Link>
        <Link to="/CreateTest" style={Links.link}>
          Create a Test
        </Link>
        <Link
          to="/EditTest"
          style={[Links.link, { backgroundColor: Colors.purple }]}
        >
          <Text style={{ color: Colors.black }}>Edit a Test</Text>
        </Link>
        <Link
          to="/ImportExport"
          style={[Links.link, { backgroundColor: Colors.gold }]}
        >
          Import and Export Tests
        </Link>
        <Link
          to="/ContactUs"
          style={[Links.link, { backgroundColor: Colors.green }]}
        >
          Contact Us
        </Link>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  headerText: { 
    ...Typography.large, 
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: Spacing.base, 
    paddingTop:25,
    padding:5,
  },
  bodyText: { 
    ...Typography.medium, 
    textAlign: 'center',
    marginBottom: Spacing.base, 
    paddingTop:10,
    padding:5,
  },
  container: {
    padding: 10,
  },
});

const text = {
  paddingTop:15,
  padding:5,
};

export default Home;
