import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet, Picker } from 'react-native';
import { Button } from './Button';
import {
  Containers,
  Buttons,
  Typography,
  Borders,
  Spacing,
  Colors,
} from '../styles';
import QuestionView from './QuestionView';
import { HotKeys } from 'react-hotkeys';
import Sound from '../modules/Sound';
import shuffle from 'fisher-yates';
import {configure} from 'react-hotkeys';
import SendPageView from '../modules/GoogleAnalitycs';

  class TestTaker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionIndex: 0,
      startScreen: false,
      questions: props.test.questions,
      answerList: [],
    };

    configure({
      ignoreEventsCondition: () => { return false; }
    });

    this.playSound = this.playSound.bind(this);
    this.stopSound = this.stopSound.bind(this);

    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleNextQuestion = this.handleNextQuestion.bind(this);
    this.handlePreviousQuestion = this.handlePreviousQuestion.bind(this);
    this.handleSwitchQuestion = this.handleSwitchQuestion.bind(this);
    this.handleStart = this.handleStart.bind(this);
  }

  _randomizeTest() {
    let resultingQuestions = [];
    const iterations = 10;
    let delta;
    let mark = 0;
    for (let j = 0; j < iterations; j++) {
      let questionCounts = {};
      let newQuestions = shuffle(
        this.state.questions.map((value, index) => index)
      );
      let totalPoints = 0;
      resultingQuestions[j] = [];
      for (let i = 0; i < newQuestions.length; i++) {
        let question = this.state.questions[newQuestions[i]];
        if (!questionCounts[question.type]) {
          questionCounts[question.type] = 0;
        }

        if (questionCounts[question.type] < this.props.test[question.type]) {
          resultingQuestions[j].push(newQuestions[i]);
          totalPoints += question.weight;
          questionCounts[question.type] += 1;
        }
      }
      let newDelta = Math.abs(this.props.test.points - totalPoints);
      if (j === 0) {
        delta = newDelta;
      }
      if (newDelta < delta) {
        delta = newDelta;
        mark = j;
      }
    }

    return resultingQuestions[mark].map((value) => this.state.questions[value]);
  }

  handleAnswerChange(answer) {
    let newAnswerList = this.state.answerList;
    newAnswerList[this.state.questionIndex] = answer;
    this.setState({ answerList: newAnswerList });
  }

  handleNextQuestion() {
    let newIndex =
      this.state.questionIndex === this.state.questions.length - 1
        ? this.state.questionIndex
        : this.state.questionIndex + 1;
    this.setState({ questionIndex: newIndex });
  }

  handlePreviousQuestion() {
    let newIndex =
      this.state.questionIndex === 0 ? 0 : this.state.questionIndex - 1;
    this.setState({ questionIndex: newIndex });
  }

  handleSwitchQuestion(value) {
    this.setState({ questionIndex: parseInt(value) });
  }

  handleStart() {
    if (this.props.test.randomizeTest) {
      this.setState({ questions: this._randomizeTest() });
    }
    this.setState({ startScreen: false });
  }

  componentDidMount() {
    this.audio = new Sound('data:audio/mpeg;base64,//==', console.log).play();
    setTimeout(function(){SendPageView();}, 500)
  }

  
  playSound() {
    this.questionView.playSound();
  }

  stopSound() {
    this.questionView.stopSound();
  }

  render() {
    let pickerOptions = [];
    for (let i = 0; i < this.state.questions.length; i++) {
      pickerOptions.push(
        <Picker.Item key={i} value={i} label={`Question ${i + 1}`} />
      );
    }
    return (
      <>
      <HotKeys handlers={{PLAY: this.playSound,STOP: this.stopSound}}>
        <View style={Containers.base}>
          {this.state.startScreen ? (
            <View style={styles.infoBox}>
              <Text
                accessible
                style={[Typography.large, { marginBottom: Spacing.base }]}
              >
                Test title: {this.props.test.title}
              </Text>
              <Text accessible style={Typography.large}>
                Test description: {this.props.test.description}
              </Text>
              {this.props.test.isTimed ? (
                <Text accessible style={Typography.large}>
                  Test time: {this.props.test.time} minutes
                </Text>
              ) : null}
              <Button
                mode="contained"
                onPress={this.handleStart}
                style={[Buttons.base, Spacing.baseStyle, styles.button]}
              >
                Start test
              </Button>
            </View>
          ) : (
            <View>
              <Text accessible style={Typography.large}>
                Current question: {this.state.questionIndex + 1}
              </Text>
              {this.state.questions[this.state.questionIndex] ? (
                <QuestionView ref={instance => { this.questionView = instance; }}
                
                  question={this.state.questions[this.state.questionIndex]}
                  key={this.state.questions[this.state.questionIndex].id}
                  onAnswerChange={this.handleAnswerChange}
                  currentAnswer={
                    this.state.answerList[this.state.questionIndex]
                  }
                />
              ) : null}
              <View style={styles.buttonNavContainer}>
                <Button
                  onPress={this.handlePreviousQuestion}
                  accessibilityLabel="Previous Question"
                  mode="contained"
                  style={[
                    Buttons.base,
                    Spacing.baseStyle,
                    styles.button,
                    { marginHorizontal: 0 },
                  ]}
                >
                  Previous
                </Button>
                {this.state.questionIndex >= this.state.questions.length - 1 ? (
                  <Button
                    accessibilityLabel="Submit Question"
                    onPress={() => {
                      this.questionView.stopSound();
                      this.props.onSubmit(
                        this.state.answerList,
                        this.state.questions
                      );
                    }}
                    style={[Buttons.base, Spacing.baseStyle, styles.button]}
                    mode="contained"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    mode="contained"
                    accessibilityLabel="Next Question"
                    onPress={this.handleNextQuestion}
                    style={[Buttons.base, Spacing.baseStyle, styles.button]}
                  >
                    Next
                  </Button>
                )}
              </View>
              <Picker
                selectedValue={this.state.questionIndex}
                accessibilityLabel="Pick question to switch to"
                onValueChange={this.handleSwitchQuestion}
              >
                {pickerOptions}
              </Picker>
            </View>
          )}
        </View>
      </HotKeys>
    </>
  );
}
}

const styles = StyleSheet.create({
  questionLabel: {
    marginBottom: Spacing.base,
  },
  button: {
    backgroundColor: Colors.gold,
  },
  buttonNavContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  infoBox: {
    padding: Spacing.base,
    margin: Spacing.base,
    ...Borders.thinRound,
    backgroundColor: Colors.lightGray,
  },
});

TestTaker.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  test: PropTypes.object.isRequired,
};

export default TestTaker;
